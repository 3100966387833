import React from 'react'
import { Link } from 'gatsby'
import { translate } from "react-i18next"

import Layout from '../components/layouts/baseLayout'

import  indexStyle from "../styles/index.module.scss"


import illu from "../images/index/how_wide_illu.svg"
import illuextra from "../images/index/how_wide_extra.svg"

import one from "../images/index/how_step_one.svg"

import two from "../images/index/how_step_two.svg"

import three from "../images/index/how_step_three.svg"
import Navbar from '../components/navbar/navbar'

function HowPage (props) {
  const { t } = props;
  return (
    <Layout>


      <div className={indexStyle.home}>


        <div className={indexStyle.accueil}>
          <div className={indexStyle.howintroduction}>
            <h1>{t("our_vision_title")}</h1>
            <p>{t("our_vision_text")}</p>
            <a className={indexStyle.lightbutton} href="#more">{t("know_more")}</a>
          </div>
        </div>
      </div>
      <div className={indexStyle.howillu}>
        <img className={indexStyle.illustrationextra} src={illuextra} alt="personnes qui covoiturent"/>
        <img className={indexStyle.illustration} src={illu} alt="personnes qui covoiturent"/>
      </div>

      <section id="more" className={indexStyle.how}>
        <h2>{t("how_title")}</h2>
        <p>{t("how_text")}</p>
      </section>

      <main className={indexStyle.main}>
        <div className={indexStyle.one}>
          <div>
            <img className={indexStyle.picture} src={one} alt="site web"/>
          </div>
          <div className={indexStyle.text}>
            <h5>{t("how_step_one")}</h5>
            <h3>{t("how_step_one_title")}</h3>
            <p>{t("how_step_one_text")}</p>
          </div>
        </div>

        <div className={indexStyle.howother}>
          <div>
            <img className={indexStyle.picture} src={two} alt="site web"/>
          </div>
          <div className={indexStyle.text}>
            <h5>{t("how_step_two")}</h5>
            <h3>{t("how_step_two_title")}</h3>
            <p>{t("how_step_two_text")}</p>
          </div>

        </div>

        <div className={indexStyle.three}>
          <div>
            <img className={indexStyle.picture} src={three} alt="site web"/>
          </div>
          <div className={indexStyle.text}>
            <h5>{t("how_step_three")}</h5>
            <h3>{t("how_step_three_title")}</h3>
            <p>{t("how_step_three_text")}</p>
          </div>
        </div>
      </main>
    </Layout>)
}


export default translate("all")(HowPage)